import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      margin: '1em',
    }} >
    <h1>Impressum</h1>
    <p>Angaben Gemäß $ 5 TMG</p>
    <p>limeriki tofu / Benjamin Winsel</p>
    <p> Naumburgerstr. 46 04279 Leipzig</p>
    <p>verwaltung@limeriki-tofu.de</p>
    <p>Steuernummer: 232/287/22278
    </p>
    <Link to="/">Go back to the homepage</Link>
    </div>
    
  </Layout>
)

export default SecondPage
